<template>
  <div>

    <b-alert
      v-if="$route.query.appointment_id && $route.query.doctor_id"
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Perjanjian <strong>{{ $route.query.doctor_name }}</strong>
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push(`/appointment/detail/${$route.query.appointment_id}`)"
      >Detail</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Tindakan Medis'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'medical-actions'"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/component/medical-actions/Form.vue";

export default {
  name: "Add",

  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {
        patient_id: this.$route.query.patient_id ?? '',
        patient_name: this.$route.query.patient_name ?? '',
        doctor_id: this.$route.query.doctor_id ?? '',
        appointment_id: this.$route.query.appointment_id ?? '',
        patient_age: "",
        electro_laser: "",
        action_type: "",
        operator: "",
        responsible_doctor:"",
        assistant: "",
        anesteshia: "",
        anesthetist: null,
        notes: "",
        nurse_notes: "",
        medicines: "",
        date: new Date().toISOString().substr(0, 10),
      },

    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan Tindakan Medis", route: "/medical-actions" },
      { title: "Tambah Laporan Tindakan Medis" },
    ]);
  },
};
</script>

<style>
</style>