<template>
  <div>
    <b-modal
      id="modal-assistant-svf"
      size="xl"
      title="Pilih Asisten SVF"
      hide-footer
      body-class="pt-0"
    >
      <!-- Tabs -->
      <div class="row">
        <div class="col-12">
          <Table
            purpose="modal-nurse"
            @chooseEmployee="chosenEmployee"
            :selectedItem="selectedItem"
            :checkbox="checkbox"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Form from "@/component/human-resource/Form.vue";
import Table from "@/component/human-resource/Table.vue";

export default {
  props: {
    selectedItem: Array,
    checkbox: Boolean,
  },

  components: {
    Form,
    Table,
  },

  methods: {
    chosenEmployee(value) {
      this.$emit("chosenEmployee", value);
    },

    submitedEmployee(value) {
      this.$emit("submitedEmployee", value);
    },
  },

  data() {
    return {
      form: {
        email: "",
        name: "",
        birt_place: "",
        birt_date: "",
        address: "",
        phone: "",
        mobile_phone: "",
        id_card_number: "",
        password: "",
        password_confirmation: "",
        position_id: "",
        depertment_id: "",
        photo: [],
        photo_name: "/images/default-profile.svg",
      },
    };
  },
};
</script>

<style>
</style>